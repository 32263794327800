import debounce from 'lodash/debounce';
import { type SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';

import { type DtoBlockDoc } from '@lp-lib/api-service-client/public';
import { type BlockType } from '@lp-lib/game';
import { fromAPIBlockTypes } from '@lp-lib/game/src/api-integration';

import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { buildReactSelectStyles } from '../../utils/react-select';
import { BlockKnifeUtils } from '../Game/Blocks/Shared';
import { BlockDocCard } from './BlockList';

async function search(
  q: string,
  callback: (blocks: DtoBlockDoc[]) => void,
  params?: {
    type?: BlockType;
    gameId?: string;
    brandIds?: string;
  }
) {
  const blocks = await apiService.block.searchBlocks(q, params).next();
  callback(blocks);
}

const debounceSearch = debounce(search, 250);

export function BlockSelect(props: {
  params?: {
    type?: BlockType;
    gameId?: string;
    brandIds?: string;
  };
  value: DtoBlockDoc | null;
  onChange: (value: DtoBlockDoc | null) => void;
  filterBlock?: (block: DtoBlockDoc) => boolean;
  disabled?: boolean;
  className?: string;
  isClearable?: boolean;
}): JSX.Element {
  const {
    params,
    value,
    onChange,
    filterBlock,
    disabled,
    className,
    isClearable,
  } = props;

  const styles = useInstance(() =>
    buildReactSelectStyles<DtoBlockDoc>({
      override: {
        control: { height: '100%' },
        option: {
          padding: 0,
        },
      },
    })
  );

  const loadOptions = (
    q: string,
    callback: (channels: DtoBlockDoc[]) => void
  ): void => {
    debounceSearch(q, callback, params);
  };

  const handleSingleChange = (option: SingleValue<DtoBlockDoc>) => {
    if (!onChange) return;
    onChange(option);
  };

  const filterOption = (option: { data: DtoBlockDoc }): boolean => {
    return filterBlock ? filterBlock(option.data) : true;
  };

  return (
    <AsyncSelect<DtoBlockDoc, false>
      placeholder={`Select the block to use`}
      styles={styles}
      cacheOptions
      loadOptions={loadOptions}
      classNamePrefix='select-box-v2'
      className={`${className} m-0`}
      value={value}
      noOptionsMessage={(obj) => {
        if (!obj.inputValue) return 'Start typing to search';
        return 'No blocks matched';
      }}
      filterOption={filterOption}
      getOptionValue={(option) => option.block.id}
      getOptionLabel={(option) =>
        BlockKnifeUtils.Summary(fromAPIBlockTypes(option.block)).title ??
        option.block.id
      }
      formatOptionLabel={(option, meta) => (
        <BlockDocCard doc={option} q={meta.inputValue} showSubtitle />
      )}
      onChange={handleSingleChange}
      isDisabled={disabled}
      isClearable={isClearable}
    />
  );
}
