import { useMemo } from 'react';

import { EnumsBrandPredefinedBlockScenario } from '@lp-lib/api-service-client/public';
import { type Block, MediaFormatVersion } from '@lp-lib/game';

import placeholder from '../../assets/img/placeholder/game-cover.png';
import { MediaUtils } from '../../utils/media';
import { CollapsibleSection } from '../common/CollapsibleSection';
import { BlockIcon, BlockRecordingIcon } from '../Game/Blocks';
import { BlockKnifeUtils } from '../Game/Blocks/Shared';
import { ArrowRightIcon } from '../icons/Arrows';
import { TBDIcon } from '../icons/TBDIcon';
import { type BrandEditorMenu, type BrandFormData } from './BrandEditor';
import { BrandUtils } from './utils';

function Cover(props: { block: Block | null }) {
  const { block } = props;

  const src = useMemo(() => {
    if (!block) return null;

    const blockSummary = BlockKnifeUtils.Summary(block);
    const mediaUrl =
      MediaUtils.PickMediaUrl(blockSummary.coverMedia, {
        priority: [MediaFormatVersion.SM],
        videoThumbnail: 'first',
      }) ?? placeholder;

    return mediaUrl;
  }, [block]);

  return (
    <div className='w-22 h-full rounded-lg border border-secondary'>
      {src && (
        <img
          src={src}
          alt='cover'
          className={`object-cover border border-secondary rounded-lg w-full h-full`}
        />
      )}
    </div>
  );
}

function PredefinedBlockBox(props: {
  scenario: EnumsBrandPredefinedBlockScenario;
  block: Block | null;
  menu: BrandEditorMenu;
  setMenu: (menu: BrandEditorMenu) => void;
  disabled: boolean;
}) {
  const { scenario, block, menu, setMenu, disabled } = props;

  const title = BrandUtils.PredefinedBlockScenarioDisplayName(scenario);
  const blockType = block
    ? block.type
    : BrandUtils.PredefinedBlockType(scenario);
  const selected =
    (menu?.kind === 'block-detail' && menu.block.id === block?.id) ||
    (menu?.kind === 'attach-block' && menu.scenario === scenario);

  const handleClick = () => {
    if (block) {
      setMenu({
        kind: 'block-detail',
        block,
        scenario,
      });
    } else {
      setMenu({
        kind: 'attach-block',
        scenario,
      });
    }
  };

  return (
    <button
      type='button'
      disabled={disabled}
      onClick={handleClick}
      className={`btn w-full h-15 p-1.5 flex justify-between border rounded ${
        selected
          ? 'border-primary bg-lp-gray-003 bg-opacity-80 hover:bg-opacity-100'
          : 'border-transparent hover:bg-lp-gray-003 hover:bg-opacity-60'
      }`}
    >
      <div className='h-full flex items-center gap-2'>
        <Cover block={block} />

        <div
          className={`text-sms font-bold ${
            block ? 'text-white' : 'text-icon-gray'
          }`}
        >
          {title}
        </div>
      </div>
      <div className='h-full flex flex-col items-end gap-2'>
        <div className='flex items-center gap-1'>
          <BlockRecordingIcon
            block={block}
            className=''
            iconClassName='w-3.5 h-3.5'
          />
          {blockType ? (
            <BlockIcon
              blockType={blockType}
              className='w-3.5 h-3.5 fill-current'
            />
          ) : (
            <TBDIcon className='w-3.5 h-3.5 fill-current' />
          )}
        </div>
        <ArrowRightIcon className='w-3.5 h-3.5 fill-current' />
      </div>
    </button>
  );
}

const ORDERED_SCENARIOS = [
  EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioInstructions,
  EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioOpeningTitle,
  EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioHostedInstructions,
  EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioDemo,
  EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard,
];

export function BrandEditorPredefinedBlocks(props: {
  predefinedBlocks: BrandFormData['predefinedBlocks'];
  menu: BrandEditorMenu | null;
  setMenu: (menu: BrandEditorMenu | null) => void;
  isBlockChanging: boolean;
}) {
  const { predefinedBlocks, menu, setMenu, isBlockChanging } = props;

  return (
    <CollapsibleSection title='Hard Coded Blocks'>
      <div className='w-full flex flex-col gap-1'>
        {ORDERED_SCENARIOS.map((scenario) => {
          const block =
            predefinedBlocks.find((item) => item.scenario === scenario)
              ?.block || null;
          return (
            <PredefinedBlockBox
              key={scenario}
              scenario={scenario}
              block={block}
              menu={menu}
              setMenu={setMenu}
              disabled={isBlockChanging}
            />
          );
        })}
      </div>
    </CollapsibleSection>
  );
}
